const UserInfoKey = 'UserInfo'
const TokenKey = 'Token'
const UserIdKey = 'UserId'

///
export function getToken() {
  var token = localStorage.getItem(TokenKey);
  return token;
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}

////
export function getUserID() {
  var userid = localStorage.getItem(UserIdKey);
  return userid;
}

export function setUserID(userId) {
  localStorage.setItem(UserIdKey, userId);
}

/////
export function getUserInfo() {
  var userInfo = JSON.parse(localStorage.getItem(UserInfoKey));
  return userInfo;
}

export function setUserInfo(userInfo) {
  console.log(userInfo)
  localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
}

// 
export function isLogin() {
  var token = localStorage.getItem(TokenKey);
  var userid = localStorage.getItem(UserIdKey);

  if (token == "undefined" || token == null) {
    return false;
  } else if (userid == "undefined" || userid == null) {
    return false;
  } else {
    return true;
  }


}





