<template>
	<div>

	<!-- 	<b-modal ref="saveModal" transition id="modal1"  @ok="handleOk" @cancel="handleCancel">
			<input  />
		</b-modal>

		<div class="c cc logobackcolor logoback textcolor" style="cursor:pointer" v-on:click="onclickadd"  v-b-modal="'modal1'">
			<b-icon icon="plus" font-scale="3"></b-icon>
		</div> -->
		<b-container class="cc">
			<div style="width: 100%">
				<b-card v-for="(item,index) in dataList" style="background-color:rgb(53, 54, 58);width: 100%"
					text-variant="white">
					<template v-slot:header>
						<h6 class="mb-0">{{item.key}}</h6>
					</template>
					<div class="flex">
						<div v-for="(subitem,subindex) in item.list" class="urlitem" style="position: relative;"
							v-bind:class="{ active:( index ==moveinindex) &&(subindex== moveinsubindex)}"
							v-on:mouseover="onmouseover(index,subindex)" v-on:mouseout="mouseout" v-on:click="onclickitem(subitem.url)" >
							<div v-show="( index ==moveinindex) &&(subindex== moveinsubindex)"
								style="position: absolute;right: 0px;top: 0px;">
								<div style="width: 20px;height: 20px;cursor:pointer;" v-on:click="onclickdelet">
									<b-icon icon="x" font-scale="1.8"></b-icon>
								</div>
								<div style="margin-top: 10px; width: 25px;height: 25px;cursor:pointer"
									v-on:click="onclickedit">
									<b-icon icon="pencil" font-scale="1"></b-icon>
								</div>

							</div>

							<div class="c cc logobackcolor logoback">
								<b-img class="faviconimg" rounded :src="subitem.faviconImg"
									style="background-color: transparent;">
								</b-img>
							</div>
							<div class="textcolor"
								style="margin-top: 8px; font-size: 12px;width: 50px;margin-left: auto;margin-right: auto;">
								{{subitem.urlName}}
							</div>


						</div>
					</div>
				</b-card>
			</div>
		</b-container>
	</div>
</template>

<script>
	import {
		Toast
	} from "vant";

	export default {
		components: {},
		data() {
			return {
				dataList: [],
				moveinindex: -1,
				moveinsubindex: -1,
			};
		},
		created: function() {
			//加载数据
			var _this = this
			this.getAxios("/HomePage/List", {}, "")
				.then(response => {
					console.log(response);
					_this.dataList = response

				})
				.catch(err => {
					console.log(err);
				});
		},

		methods: {
			onclickitem:function(url){
				      window.location.href = url
			},
		 
			//鼠标移上去
			onmouseover: function(index, subindex) {

				this.moveinindex = index;
				this.moveinsubindex = subindex;
			},
			mouseout: function() {
				this.moveinindex = -1;

			},
			//删除
			onclickdelet: function() {
				var _this = this
				var data = this.dataList[this.moveinindex].list[this.moveinsubindex]
				console.log(data)
				this.getAxios("/HomePage/Delete?id=" + data.id, {}, "")
					.then(response => {
						console.log(response);
						_this.dataList = response

					})
					.catch(err => {
						console.log(err);
					});
			},
			//编辑
			onclickedit: function() {

			},
			//增加
			onclickadd: function() {

			},
			handleOk:function(){
				
			},
			handleCancel:function(){
				
			},
			
		}
	};
</script>

<style>
	.urlitem {
		width: 140px;
		height: auto;

		text-align: center;
		padding: 25px;
	}

	.logoback {
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}

	.logobackcolor {
		background-color: rgba(32, 33, 36, 1);
	}

	.textcolor {
		color: rgb(232, 234, 237, 1);
	}

	.active {
		background-color: rgb(43, 44, 48);
	}

	.icoactive {
		background-color: rgb(33, 34, 38);
	}

	.faviconimg {
		height: 28px;
		width: 28px;
	}




	.flex {
		display: flex;
		flex-wrap: wrap;
	}

	.cc {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.c {
		margin-left: auto;
		margin-right: auto;
	}
</style>
