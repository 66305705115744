import axios from 'axios'
import qs from 'qs'
import {
	baseURL
} from './config.js'
import {
	getToken,
	getUserID,
	isLogin
} from '@/api/localStorage'
import {
	Toast,
	Dialog
} from 'vant';

const quee = [];

// 添加请求拦截器，在发送请求之前做些什么(**具体查看axios文档**)--------------------------------------------
axios.interceptors.request.use(function(config) {
	// 显示loading
	if (getUserID() != null) {
		config.headers['userid'] = getUserID()
	}

	if (getToken() != null) {
		config.headers['token'] = getToken()
	}
	return config
}, function(error) {
	console.log("request拦截器错误", error)
	return Promise.reject(error)
})

// 添加响应拦截器(**具体查看axios文档**)----------------------------------------------------------------
axios.interceptors.response.use(function(response) {
	// 对响应数据做点什么，允许在数据返回客户端前，修改响应的数据
	// 如果只需要返回体中数据，则如下，如果需要全部，则 return response 即可
	// status 不放这里判断是应为还要根据具体方法调用的showtest来配合执行 所以放在successState
	return response
}, function(error) {
	console.log("response拦截器错误", error)
	Toast.fail("服务器跑丢了");
	return Promise.reject(error)
})

// 封装数据返回失败提示函数---------------------------------------------------------------------------
function errorState(response) {
	console.log("errorState错误,因为我吧错误范围拉大了，估计这里用不到", response)
	return response
}

// 封装数据返回成功提示函数---------------------------------------------------------------------------
function successState(res, showstr) {
	// 统一判断后端返回的错误码(错误码与后台协商而定)
	Toast.clear();

	if (res.status == 200) {
		if (showstr) {
			Toast.loading({
				message: showstr + "成功",
				forbidClick: true,
				duration: 500,
				type: "success"
			});
		}
	} else if (res.status == 503) {
		Toast.fail(res);
	} else if (res.status == 400) {
		Dialog.alert({
			message: res.data.error,
		}).then(() => {
			// on close
		});
		// Toast.fail(res.data.error);
	} else if (res.status == 401) {
		Toast.fail(res.data);
	} else if (res.status == 500) {
		Toast.fail("服务器跑丢了");
	}
	return res.data
}

// 封装axios--------------------------------------------------------------------------------------
function apiAxios(method, url, params, showstr, successfun) {
	let httpDefault = {
		method: method,
		baseURL: baseURL,
		url: url,
		// `params` 是即将与请求一起发送的 URL 参数
		// `data` 是作为请求主体被发送的数据
		params: method === 'GET' || method === 'DELETE' ? params : null,
		//qs.stringify(params) 
		data: method === 'POST' || method === 'PUT' ? params : null,
		timeout: 10000,
		validateStatus: function(status) {
			return status >= 200 && status <
			501; // 如果不加这个范围定义，默认200-300的会直接被error里reject，因为error里都找不到错误提示文字，所以都放到正确里判断
		},
	}
	if (showstr) {
		Toast.loading({
			message: showstr + "中...",
			forbidClick: true,
			duration: 0,
		});
	}


	// 注意**Promise**使用(Promise首字母大写)
	return new Promise((resolve, reject) => {
		axios(httpDefault)
			// 此处的.then属于axios
			.then((res) => {
				if (typeof(successfun) == 'function') {
					successfun(res.data)
				}
				successState(res, showstr)
				resolve(res.data)
			}).catch((response) => {
				errorState(response)
				reject(response)
			})
	})
}

//Q是需要检查是否已经登录的请求
function apiAxiosQ(method, url, params, showstr, successfun) {

	if (isLogin()) {
		apiAxios(method, url, params, showstr, successfun)
	} else {
		add({
			method: method,
			url: url,
			params: params,
			showstr: showstr,
			successfun: successfun
		})
	}
}

function add(params) {
	quee.push(params)

}

function doquee() {

	while (quee.length) {

		var params = quee.pop();

		apiAxios(params.method, params.url, params.params, params.showstr, params.successfun);
	}
}

// 输出函数getAxios、postAxios、putAxios、delectAxios，供其他文件调用-----------------------------
// Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
export default {
	install: function(Vue) {
		Vue.prototype.getAxios = (url, params, showstr) => apiAxios('GET', url, params, showstr)
		Vue.prototype.postAxios = (url, params, showstr) => apiAxios('POST', url, params, showstr)
		Vue.prototype.putAxios = (url, params, showstr) => apiAxios('PUT', url, params, showstr)
		Vue.prototype.delectAxios = (url, params, showstr) => apiAxios('DELECT', url, params, showstr)

		Vue.prototype.getAxiosQ = (url, params, showstr, successfun) => apiAxiosQ('GET', url, params, showstr,
			successfun)
		Vue.prototype.postAxiosQ = (url, params, showstr, successfun) => apiAxiosQ('POST', url, params, showstr,
			successfun)
		Vue.prototype.doquee = () => doquee()
	},


}
