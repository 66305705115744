<template>
  <div>
    <div v-for="(item,index) in itemlist" :key="index">
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
      <tbitem :item="item"></tbitem>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import TbItem from "@/components/TbItem.vue";

export default {
  metaInfo: {
    title: "曼哥返利",
    meta: [
      {
        name: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利",
        content: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利"
      }
    ],
    link: [
      {
        rel: "supersearch",
        href: "https://tools.mgee.vip/supersearch"
      }
    ]
  },
  components: {
    tbitem: TbItem
  },
  data() {
    return {
      itemlist: [
        {
            tlj:20,//自己加一个淘礼金
          category_id: 50018406,
          category_name: "按摩椅/沙发",
          click_url:
            "//s.click.taobao.com/t?e=m%3D2%26s%3DjjAL4Cl3OsNw4vFB6t2Z2ueEDrYVVa64Dne87AjQPk9yINtkUhsv0HySpyM0dz1d5hRPBrmc%2F5q2Dvm4BHyB79xahCq0w09c1kQO%2BrG%2FINe8%2Bo0%2F5A3j4sRQXCWCjhBSLrHGZgcOhEBtlVrcJOM%2B%2FaB2ypTe%2F3zREiM%2FlSG%2FbZTyCxOn5TPzApUdzpVKiDiPfGyCJw15z4vGDF1NzTQoPw%3D%3D&scm=1007.14822.173006.0&pvid=b4d9df0d-922c-4131-8d31-ff23dbf7fd56&app_pvid=59590_11.1.73.68_37080_1594889011145&ptl=floorId:13249;originalFloorId:13249;pvid:b4d9df0d-922c-4131-8d31-ff23dbf7fd56;app_pvid:59590_11.1.73.68_37080_1594889011145&xId=EYw35SKzY8kj0zpC60xrrYk6dc3flQwwbjWlNMt3qHkC4xVof7NbejX8iddwbIOml6WlQxEOZjiiwll0wK0XfeuDwoN7plkg19rWNie1UYQO0W1ZgBTXRJlWwnsyZnfxY&union_lens=lensId%3AMAPI%401594889011%40b4d9df0d-922c-4131-8d31-ff23dbf7fd56_605272376402%401",
          commission_rate: "1.61",
          coupon_amount: 1000,
          coupon_click_url:
            "//uland.taobao.com/coupon/edetail?e=%2B2MFVTYMf%2BQNfLV8niU3R5TgU2jJNKOfNNtsjZw%2F%2FoIQuHudfI2sOC3PpjByQWC8hP%2FgZ8Gi6Ah41pORvOCzZcuRTiT9oEhVZV8pr6FWc0PtYdz7wZk%2FYfM0YkL7WCSxmMHpNfYdHdDLQk5a%2BLPhJFjVWaYavOY1DXsgfi5UemiSXcV56LBps5npyL3Ia2QyImKPjjAjoQSie%2FpBy9wBFg%3D%3D&&app_pvid=59590_11.1.73.68_37080_1594889011145&ptl=floorId:13249;app_pvid:59590_11.1.73.68_37080_1594889011145;tpp_pvid:b4d9df0d-922c-4131-8d31-ff23dbf7fd56&xId=EYw35SKzY8kj0zpC60xrrYk6dc3flQwwbjWlNMt3qHkC4xVof7NbejX8iddwbIOml6WlQxEOZjiiwll0wK0XfeuDwoN7plkg19rWNie1UYQO0W1ZgBTXRJlWwnsyZnfxY&union_lens=lensId%3AMAPI%401594889011%40b4d9df0d-922c-4131-8d31-ff23dbf7fd56_605272376402%401",
          coupon_end_time: "1595260799000",
          coupon_remain_count: 99855,
          coupon_share_url:
            "//uland.taobao.com/coupon/edetail?e=%2B2MFVTYMf%2BQNfLV8niU3R5TgU2jJNKOfNNtsjZw%2F%2FoIQuHudfI2sOC3PpjByQWC8hP%2FgZ8Gi6Ah41pORvOCzZcuRTiT9oEhVZV8pr6FWc0PtYdz7wZk%2FYfM0YkL7WCSxmMHpNfYdHdDLQk5a%2BLPhJFjVWaYavOY1DXsgfi5UemiSXcV56LBps5npyL3Ia2QyImKPjjAjoQSie%2FpBy9wBFg%3D%3D&&app_pvid=59590_11.1.73.68_37080_1594889011145&ptl=floorId:13249;app_pvid:59590_11.1.73.68_37080_1594889011145;tpp_pvid:b4d9df0d-922c-4131-8d31-ff23dbf7fd56&xId=EYw35SKzY8kj0zpC60xrrYk6dc3flQwwbjWlNMt3qHkC4xVof7NbejX8iddwbIOml6WlQxEOZjiiwll0wK0XfeuDwoN7plkg19rWNie1UYQO0W1ZgBTXRJlWwnsyZnfxY&union_lens=lensId%3AMAPI%401594889011%40b4d9df0d-922c-4131-8d31-ff23dbf7fd56_605272376402%401",
          coupon_start_fee: "2200.0",
          coupon_start_time: "1594742400000",
          coupon_total_count: 100000,
          item_description: "免安装 顺丰包邮送上楼  全国联保 上门维修",
          item_id: 605272376402,
          level_one_category_id: 50002768,
          level_one_category_name: "个人护理/保健/按摩器材",
          nick: "佳仁旗舰店",
          pict_url:
            "//img.alicdn.com/bao/uploaded/i3/1751803853/O1CN01a5zdYu1eKieU3dQrO_!!0-item_pic.jpg",
          seller_id: 1751803853,
          shop_title: "佳仁旗舰店",
          short_title: "德国佳仁电动新款全自动家用按摩椅",
          small_images: {
            string: [
              "//img.alicdn.com/i2/1751803853/O1CN01U5VCUZ1eKie6N5u4k_!!1751803853.jpg",
              "//img.alicdn.com/i3/1751803853/O1CN010ZEPCo1eKieESVIFd_!!1751803853.jpg"
            ]
          },
          sub_title: "免安装 顺丰包邮送上楼  全国联保 上门维修",
          title: "德国佳仁电动新款按摩椅全自动家用小型太空豪华舱全身多功能老人",
          user_type: 1,
          volume: 7516,
          white_image:
            "https://img.alicdn.com/bao/uploaded/TB1B4ADL.T1gK0jSZFrXXcNCXXa.png",
          x_id:
            "EYw35SKzY8kj0zpC60xrrYk6dc3flQwwbjWlNMt3qHkC4xVof7NbejX8iddwbIOml6WlQxEOZjiiwll0wK0XfeuDwoN7plkg19rWNie1UYQO0W1ZgBTXRJlWwnsyZnfxY",
          zk_final_price: "2280"
        }
      ]
    };
  },
  created: function() {},
  watch: {},
  methods: {}
};
</script>

<style scoped>
</style>
