<template>
  <div class="con">
    <b-modal id="modal2" hide-footer hide-header centered>
      <div class="d-block text-center">
        <div>{{title}}</div>
        <div>
          <van-loading v-if="!ewmurl" />
          <qriously v-if="ewmurl" :value="ewmurl" :size="200" />
        </div>
      </div>
      <b-button class="mt-3" block @click="close">{{button}}</b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  getUserInfo,
  setUserInfo,
  setToken,
  setUserID,
  isLogin
} from "@/api/localStorage";
import { Toast } from "vant";
export default {
  name: "TaskLogin",
  props: {
    ewmurl: String,
    title: {
      type: String,
      default: "临时用户您好，请扫码关注公众号。"
    },
    button: {
      type: String,
      default: "取消"
    }
  },
  data() {
    return {
      ischecking: false
    };
  },
    created: function() {
    //`this` 指向 vm 实例
    //console.log("code is: " + this.code);
    // console.log("code is: " + this.$route.query.code);
    // this.code = this.$route.query.code;
    // if (this.code) {
    //   console.log("有code是微信客户端自动登录");
    //   this.wxoauth2(this.code);
    // }
  },

  methods: {
    checkguestLogin(taskid) {
      this.getAxios("/User/CheckTempLogin", {
        guid: taskid
      })
        .then(response => {
          console.log(response);
          if (response == "no") {
            if (this.ischecking) {
              this.checkguestLogin(taskid);
            }
          } else {
            Toast.success("登录成功");
            this.close();
            this.userinfo = response;
            setUserInfo(response);
            setUserID(this.userinfo.id);
            setToken(this.userinfo.openId);
            //身份验证完成， 进行队列请求
            this.doquee();
            $userinfo.setUserInfo();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    open(taskid) {
      this.$bvModal.show("modal2");
      this.ischecking = true;
      this.checkguestLogin(taskid);
    },
    close() {
      this.ischecking = false;
      this.$bvModal.hide("modal2");
    },
      /*
    首先请注意，这里通过code换取的是一个特殊的网页授权access_token,与基础支持中的access_token（该access_token用于调用其他接口）不同。公众号可通过下述接口来获取网页授权access_token。如果网页授权的作用域为snsapi_base，则本步骤中获取到网页授权access_token的同时，也获取到了openid，snsapi_base式的网页授权流程即到此为止。
    */
    wxoauth2(code) {
      this.getAxios(
          "https://mpservice.kemangouwu.top/Auth/Auth2?code=" + code
        )
        .then(response => {
          // this.meals = response.data.categories;
            console.log("登录成功");
            console.log(response);
            this.userinfo = response;
            setUserInfo(response);
            setUserID(this.userinfo.id);
            setToken(this.userinfo.openId);
            //身份验证完成， 进行队列请求
            this.doquee();
            $userinfo.setUserInfo();
        })
        .catch(err => {
          console.log(err);
        });
    }

  }
};
</script>
<style scoped>
</style>