import { render, staticRenderFns } from "./jd.vue?vue&type=template&id=870200da&scoped=true&"
import script from "./jd.vue?vue&type=script&lang=js&"
export * from "./jd.vue?vue&type=script&lang=js&"
import style0 from "./jd.vue?vue&type=style&index=0&id=870200da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "870200da",
  null
  
)

export default component.exports