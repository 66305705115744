<template>
  <div class="container con">
    <div style="height: 25px"></div>

    <b-card-group deck>
      <!-- <div style="height: 925px"></div> -->
      <div>2种口令自由选择：</div>
      <div style="height: 15px"></div>
      <b-card
        :id="index"
        v-for="(item, index) in toolsList"
        :key="index"
        v-b-hover="handleHover"
        :border-variant="item.isHovered ? item.bordervariant : ''"
        :header="item.header"
        :header-bg-variant="item.headerbgvariant"
        :header-text-variant="item.istest ? 'gray' : item.headertextvariant"
        :bg-variant="item.bgvariant"
        align="center"
        @click="copy(item.type)"
      >
        <b-card-text id="foo"
          >{{ item.des }}
          <div>【点击生成】</div></b-card-text
        >
      </b-card>
    </b-card-group>
    <div @click="docopy()" id="clickbutton">{{ tkl }}</div>
    <div class="textclass" id="tkl">{{ tklreout }}</div>
    <!-- <div >{{ tkl }}</div> -->
    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast } from "vant";
import TaskLogin from "@/components/TaskLogin.vue";

export default {
  components: {
    tasklogin: TaskLogin,
  },
  data() {
    return {
      qCode: "",
      itemid: "",
      tkl: "",
      tklreout: "",
      clipboardobj: {},
      toolsList: [
        {
          bordervariant: "info",
          header: "礼金口令",
          headerbgvariant: "info",
          headertextvariant: "white",
          des: "发放淘礼金，提前享受折扣，适用于自购。",
          isHovered: false,
          type: "lj",
          tkl: "",
        },
        {
          bordervariant: "primary",
          header: "返利口令",
          headerbgvariant: "primary",
          headertextvariant: "white",
          des: "传统返现，确认收获结算后返利，适用于分享给别人。",
          isHovered: false,
          type: "fl",
          tkl: "",
        },
      ],
    };
  },
  created: function () {
    this.itemid = this.$route.query.itemid;

    // FastClick.attach(document.body);
  },

  methods: {
    copy(type) {
      console.log(type);
      switch (type) {
        case "lj":
          this.getLj();
          break;
        case "fl":
          this.getfl();
          break;
      }
    },
    docopy() {
      var createInput = document.createElement("input");
      // 防止键盘被调起
      createInput.setAttribute("readonly", "readonly");
      createInput.setAttribute("value", this.tkl);
      document.body.appendChild(createInput);

      createInput.select(); //ios 不支持 但chrome 支持
      createInput.setSelectionRange(0, createInput.value.length); // chrome 不支持 但ios支持
      // alert(createInput.value.length)
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        alert("复制成功");
      } else {
        //   alert("你的手机不支持复制功能");
      }
      document.body.removeChild(createInput);
    },

    getLj() {
      this.getAxios("/Fanli/CopyTlj", { itemid: this.itemid }, "生成").then(
        (response) => {
          if (!response.iskoul) {
            alert("此宝贝返利太低不足发放淘礼金，只能返利。");
          }
          this.tkl = response.koul;
          this.tklreout = "【点击口令可以直接复制】";

          //         if (response.iskoul) {
          //           this.tkl = response.koul;
          //       this.tklreout="【点击口令可以直接复制】"
          // //         var  button =document.getElementById("clickbutton")
          // // button.click()

          //         } else {
          //         }
        }
      );
    },
    getfl() {
      this.getAxios("/Fanli/CopyFanLi", { itemid: this.itemid }, "生成")
        .then((response) => {
          console.log(response);
          this.tkl = response.koul;
          this.tklreout = "【点击口令可以直接复制】";
          //           if (response.iskoul) {
          //             this.tkl = response.koul;
          //             this.tklreout="【点击口令可以直接复制】"
          // //   var  button =document.getElementById("clickbutton")
          // // button.click()

          //           } else {
          //           }
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },

    handleHover(e, b) {
      var index = 0;
      if (e) {
        index = b.toElement.id;
      } else {
        index = b.fromElement.id;
      }

      this.toolsList[index].isHovered = !this.toolsList[index].isHovered;
    },
  },
};
</script>

<style scoped>
.textclass {
  text-align: center;
}
</style>
