<template>
  <div class="container con">
    <div style="height: 50px;"></div>
    <h2>建议&问题 反馈</h2>
    <div style="height: 40px;"></div>
    <!-- <b-form-input v-model="inputtext" :placeholder="placeholdertext"></b-form-input> -->

    <b-form-textarea v-model="inputtext" :placeholder="placeholdertext" rows="3"></b-form-textarea>

    <div style="height: 10px;"></div>
    <b-button block variant="success" @click="add">{{buttontext}}</b-button>
    <div style="height: 10px;"></div>

    <van-tabs v-model="tabaction" background="lightgray" title-active-color="green" color="green">
     
      <van-tab title="历史记录 ">
        <div class="boxview">
          <div class="list">
            <van-cell
              class="cellitem"
              center
              v-for="item in datalist"
              :key="item.id"
              :title="item.richText"
              :label="item.creatTime"
            >
              <template #right-icon>
                <div v-if="item.taskProgress==0"></div>
                <div v-if="item.taskProgress==1">{{item.myRe}}</div>
               
              </template>
            </van-cell>
          </div>
          <div class="pagecount">
            <van-pagination
              v-model="currentPage"
              :total-items="totalitems"
              :show-page-size="5"
              :items-per-page="5"
              force-ellipses
            />
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isLogin } from "@/api/localStorage";
import TaskLogin from "@/components/TaskLogin.vue";

export default {
  metaInfo: {
    title: "建议&问题 反馈",
    meta: [
      {
        // set meta
        name: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利",
        content: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利"
      }
    ],
    link: [
      {
        // set link
        rel: "report",
        href: "https://tools.mgee.vip/report"
      }
    ]
  },
  components: {
    tasklogin: TaskLogin
  },
  data() {
    return {
      qCode: "",
      datalist: [],

      currentPage: 1,
      tabaction: 0,
      totalitems: 0,

      placeholdertext: "感谢您的反馈。",
      buttontext: "提交",
      inputtext: ""
    };
  },
  created: function() {
    this.onLoadList();
  },
  watch: {
    //由于组件自带的变更页数有bug 用vue监控数据来控制加载数据
    currentPage(data) {
   
      this.onLoadList();
    }
  },
  methods: {
    add() {
      var guest = "AddTask";
      var islogin = isLogin();
      if (!islogin) {
       Toast.fail("未登录");
        return;
      }
      if (this.inputtext.length <2) {
        Toast.fail("请输入多一些内容");
        return;
      }
      this.getAxios(
        "/Report/Add" ,
        { richText: this.inputtext },
        "提交"
      ).then(response => {
        if (islogin) {
          this.totalitems = 0;
          this.pageIndex = 1;
          this.onLoadList();
        } else {
          //没登陆的 显示二维码
          this.qCode = response.ewm;
          this.$refs.tasklogin1.open(response.taskId);
        }
      });
    },
    changepage() {
      this.onLoadList();
    },

    onLoadList() {
      var _this = this;
      this.getAxiosQ(
        "/Report/List",
        {
          pageIndex: this.currentPage,
          pageSize: 5
        },
        "",
        function(response) {
          if (_this.totalitems == 0) {
            _this.totalitems = response.totalitem;
          }
          var index = 1;
          response.list.forEach(element => {
            element.richText =
              "【" +
              (index + (_this.currentPage - 1) * 5) +
              "】" +
              element.richText +
              "";
            index = index + 1;
          });
          if (response.list.length > 0) {
            _this.tabaction = 1;
          }

          _this.datalist = response.list;
        }
      );
    },
    exportpng: function(url) {
      window.location.href = url;
    }
  }
};
</script>

<style scoped>
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}
.radio {
  align-items: left;
  text-align: left;
}
.boxview {
  padding: 10px 10px 0px 10px;
  text-align: left;
  min-height: 280px;
  border-style: solid;
  border-color: lightgray;
  background-color: white;
}
.list {
  min-height: 240px;
}
.cellitem {
  border-bottom: 1px solid lightgray;
}
</style>
