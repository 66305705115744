<template>
  <div class="container">
    <div style="margin-top: 20px; width: 100%">
      <div>
        淘礼金已为您节约：<label class="redtext">{{
          tljListPage.tljtotal
        }}</label>
        元
      </div>

      <div style="margin-top: 5px">
        享受现金返现：<label class="redtext">{{ fxListPage.fxtotal }}</label> 元
      </div>
      <van-tabs
        style="margin-top: 5px"
        v-model="tabactive"
        type="card"
        animated
      >
        <van-tab title="返现记录">
          <van-cell
            class="cellitem"
            center
            v-for="item in fxListPage.list"
            :key="item.id"
            :label="item.label"
          >
            <template #title>
              <div style="display: flex; justify-content: space-between">
                <div>
                  <van-tag v-if="item.fxstatus == 0" plain type="primary">
                    待结算
                  </van-tag>
                  <van-tag v-if="item.fxstatus == 1" type="primary">
                    已结算
                  </van-tag>
                  返利金额：￥ {{ item.je }}
                </div>
                <div>
                  <van-tag v-if="item.orderType == 2" type="danger">
                    京东
                  </van-tag>
                </div>
              </div>
            </template>
          </van-cell>
        </van-tab>
        <van-tab title="礼金记录">
          <van-cell
            class="cellitem"
            center
            v-for="item in tljListPage.list"
            :key="item.id"
            :label="item.label"
          >
            <template #title>
              <div style="display: flex; justify-content: space-between">
                <div>享受礼金：￥ {{ item.je }}</div>
                <div>
                  <van-tag v-if="item.orderType == 2" type="danger">
                    京东
                  </van-tag>
                </div>
              </div>
              <div style="word-break:break-all; display:-webkit-box; -webkit-line-clamp:1; -webkit-box-orient:vertical;
	overflow:hidden;"> 宝贝：{{item.title}} </div>
            </template>
          </van-cell>
        </van-tab>
        <van-tab title="提现历史">
          <van-cell
            class="cellitem"
            center
            v-for="item in txListPage.list"
            :key="item.id"
            :title="item.remarks"
            :label="item.creatTime"
          >
            <template #right-icon>
              <div v-if="item.rStatus == 0">处理中</div>
              <div v-if="item.rStatus == 2">审核失败</div>
              <div v-if="item.rStatus == 3">完成</div>
            </template>
          </van-cell>
        </van-tab>
      </van-tabs>
    </div>
    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast, Icon, List } from "vant";
import { isLogin } from "@/api/localStorage";
import TaskLogin from "@/components/TaskLogin.vue";
export default {
  components: {
    tasklogin: TaskLogin,
  },

  data() {
    return {
      tabactive: 0,
      qCode: "",
      showForm: false,
      fanliInof: {
        yue: "查询中",
      },
      txForm: {
        txje: "",
        alipayid: "",
        alipayName: "",
        wexinpayid: "暂不支持微信支付到账号",
      },
      txListPage: {
        list: [],
      },
      fxListPage: {
        list: [],
        fxtotal: "计算中",
      },
      tljListPage: {
        list: [],
        tljtotal: "计算中",
      },
    };
  },

  created: function () {
    this.fxList();
    this.txList();
    this.tljList();
  },

  methods: {
    txList: function () {
      var _this = this;
      this.getAxios(
        "/ToolsFanLi/TxList",
        {
          pageIndex: this.currentPage,
          pageSize: 5,
        },
        ""
      ).then((response) => {
        _this.txListPage.list = response;
        console.log("提现list：");
        console.log(response);
      });
    },
    fxList: function () {
      var _this = this;
      this.getAxios("/ToolsFanLi/FxList", "查询").then((response) => {
        _this.fxListPage.list = response.datalist;
        _this.fxListPage.fxtotal = response.fxtotal;
        console.log(response);
      });
    },
    tljList: function () {
      var _this = this;
      this.getAxios("/ToolsFanLi/LJList", "查询").then((response) => {
        console.log("返利返回：");
        console.log(response);
        _this.tljListPage.list = response.datalist;
        _this.tljListPage.tljtotal = response.tljtotal;
      });
    },
  },
};
</script>

<style scoped>
.jdicon {
  widows: 25px;
  height: 25px;
}
.redtext {
  color: red;
  font-weight: 700;
}
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}

.txbutton {
  width: 180px;
}

.mup {
  margin-top: 60px;
}

.blackgroup {
  width: 200px;
  background-color: #39a9ed;
  height: 20px;
}
</style>
