import { render, staticRenderFns } from "./FanLiList.vue?vue&type=template&id=fbd7ca5e&scoped=true&"
import script from "./FanLiList.vue?vue&type=script&lang=js&"
export * from "./FanLiList.vue?vue&type=script&lang=js&"
import style0 from "./FanLiList.vue?vue&type=style&index=0&id=fbd7ca5e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd7ca5e",
  null
  
)

export default component.exports