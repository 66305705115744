<template>
    <div class="container con">

        <div style="font-size: x-large;">
            <div> 唐包大人 网盘：</div>
            <a href="https://wwal.lanzoue.com/b036xouli"> https://wwal.lanzoue.com/b036xouli</a>
            <div> 密码： tbdr</div>
        </div>
        <div style="margin: 30px;"> =============================</div>
        <div style="font-size: x-large;">
         
            <div> 远方小熊猫 网盘：</div>
            <a href=" https://www.lanzoue.com/b03jcn6va"> https://www.lanzoue.com/b03jcn6va</a>
            <div> 密码:9702</div>
        </div>





        <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
    </div>
</template>

<script>

import TaskLogin from "@/components/TaskLogin.vue";
export default {
    components: {
        tasklogin: TaskLogin,
    },

    data() {
        return {
            qCode: "",
        };
    },

    created: function () {

    },

    methods: {
        selectword: function () {
            // console.log("1111111111111")
        }
    },
};
</script>

<style scoped>
.con {
    align-content: center;
    align-items: center;
    text-align: center;
}
</style>
