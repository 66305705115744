import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/Index'
import MP3 from '@/views/MP3'
import WenKu from '@/views/Wenku'
import Douyin from '@/views/Douyin'
import SuperSearch from '@/views/SuperSearch'
import SearchView from '@/views/SearchView'
import Fanli from '@/views/FanLi'
import FanLiList from '@/views/FanLiList'
import GetKoulin from '@/views/taoke/getkoulin'
import Report from '@/views/Report'
import taokejd from '@/views/taoke/jd'
import taoketb from '@/views/taoke/tb'
import queryOrder from '@/views/taoke/QueryOrder'
import bydhb from '@/views/byd/hb'

import home from '@/views/home'

Vue.use(Router)

export default new Router({
	//Hash: 使用URL的hash值来作为路由。支持所有浏览器。 
	//History: 以来HTML5 History API 和服务器配置。参考官网中HTML5 History模式 
	//Abstract： 支持所有javascript运行模式。如果发现没有浏览器的API，路由会自动强制进入这个模式。
	mode: 'history',
	routes: [
		{
			path: '*',
			name: '404',
			component: home
		},
		{
			path: '/index',
			name: 'index',
			component: Index
		},
		{
			path: '/home',
			name: 'home',
			component: home
		},


		{
			path: '/',
			name: 'index',
			component: Index
		},
		{
			path: '/Fanli',
			name: 'Fanli',
			component: Fanli,
			meta: {
				title: '提现-曼哥工具箱'
			}
		},
		{
			path: '/FanLiList',
			name: 'FanLiList',
			component: FanLiList,
			meta: {
				title: '返利详情-曼哥工具箱'
			}
		},
		{
			path: '/MP3',
			name: 'MP3',
			component: MP3,
			meta: {
				title: 'MP3分享-曼哥工具箱'
			}
		},
		{
			path: '/WenKu',
			name: 'WenKu',
			component: WenKu,
			meta: {
				title: '文库下载-曼哥工具箱'
			}
		},
		{
			path: '/douyin',
			name: 'DY',
			component: Douyin,
			meta: {
				title: '视频去水印-曼哥工具箱'
			}
		},
		{
			path: '/SuperSearch',
			name: 'SuperSearch',
			component: SuperSearch,
			meta: {
				title: '超级搜索-曼哥工具箱'
			}
		},
		{
			path: '/SearchView',
			name: 'SearchView',
			component: SearchView,
			meta: {
				title: '超级搜索'
			}
		},
		{
			path: '/GetKoulin',
			name: 'GetKoulin',
			component: GetKoulin,
			meta: {
				title: '拷贝口令'
			}
		},
		{
			path: '/Report',
			name: 'Report',
			component: Report,
			meta: {
				title: '反馈Bug-曼哥工具箱'
			}
		},
		{
			path: '/taoke/jd',
			name: 'taokejd',
			component: taokejd,
			meta: {
				title: '京东返利-曼哥工具箱'
			}
		},
		{
			path: '/taoke/tb',
			name: 'taoketb',
			component: taoketb,
			meta: {
				title: '淘宝返利-曼哥工具箱'
			}
		},
		{
			path: '/taoke/queryOrder',
			name: 'queryOrder',
			component: queryOrder,
			meta: {
				title: '淘宝返利-订单查询'
			}
		},
		{
			path: '/byd',
			name: 'bydhb',
			component: bydhb,
			meta: {
				title: '海豹车机'
			}
		}



	]
})
