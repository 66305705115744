<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-container>
        <b-navbar-brand href="#" @click="gotolink">MGee工具箱</b-navbar-brand>

        <b-navbar-nav>
          <b-nav-item href="#" @click="gotolink">一些小工具</b-nav-item>
        </b-navbar-nav>

        <!-- <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#">生活小工具</b-nav-item>
            <b-nav-item href="#">购物好帮手</b-nav-item>
            <b-nav-item href="#">IOS快捷指令</b-nav-item>
          </b-navbar-nav>
        </b-collapse>-->

        <b-navbar-nav class="ml-auto">
          <div @click="onclickuser()">
            <!-- <b-avatar :src="userinfo.headimgurl"></b-avatar> -->
            <div class="logintext" v-if="userinfo.jiFen!=-1">
              用户编号：{{ userinfo.id }}
            </div>
            <div class="logintext" v-else>
              点击登录
            </div>
          </div>
        </b-navbar-nav>
        <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
      </b-container>
    </b-navbar>
    <b-modal id="modal-1" hide-footer hide-header>
      <div class="d-block text-center">
        <div>微信扫码登录</div>
        <div>
          <van-loading v-if="!initQCode" />
          <qriously v-if="initQCode" :value="initQCode" :size="200" />
        </div>
      </div>
      <b-button class="mt-3" block @click="closemodal">取消</b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  getUserInfo,
  setUserInfo,
  setToken,
  setUserID,
  isLogin,
} from "@/api/localStorage";
import { Toast } from "vant";
export default {
  data() {
    return {
      initQCode: "",
      loginguid: "",
      userinfo: {
        jiFen: -1,
        headimgurl: "",
      },
      ischecking: false,
    };
  },
  created: function () {
    //先判断本地是否存了 信息，
	console.log("Email:K@MGee.vip")
    var userinfo = getUserInfo();
    if (userinfo == null) {
      // console.log("微信带来code： " + this.$route.query.code); //mp微信身份授权
      this.code = this.$route.query.code;
      if (this.code) {
        this.getAuth(this.code);
      } else {
        // console.log("没有微信来的code");
      }
    } else {
      this.userinfo = userinfo;
      // console.log("本地用户");
    }
    window.$userinfo = this;
  },

  methods: {
    getMeal() {},
    closemodal() {
      this.$bvModal.hide("modal-1");
      this.ischecking = false;
    },
    onclickuser() {
      if (isLogin()) {
        Toast.success("已经登陆");
      } else {
        if (this.isWeiXin()) {
          this.gotoAuthurl();
        } else {
          // this.gotoAuthurl()
          this.openmodal();
        }
      }
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    gotoAuthurl() {
      var thisurl = window.location.href;

      var url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx76203373b398e4f2&redirect_uri=" +
        encodeURIComponent(thisurl) +
        "&response_type=code&scope=snsapi_base&state=state#wechat_redirect";
      window.location.href = url;
    },

    openmodal() {
      //判断是否是pc用户 点开后弹窗
      this.$bvModal.show("modal-1");
      this.getEWM();
      this.ischecking = true;

      //测试用的 设置本地数据
      // setUserID(15);
      // setToken("oELmywnJmUXvjTOTHWDm6iBiUTsE");
    },
    getEWM() {
      if (this.initQCode) {
        return;
      }
      this.getAxios("/User/GetLoginQr", {})
        .then((response) => {
          this.initQCode = response.url;
          this.loginguid = response.guid;
          this.checklogin();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAuth(code) {
      this.getAxios(
        "/User/Auth",
        {
          code: code,
        },
        "登录"
      )
        .then((response) => {
          console.log(response);
          this.userinfo = response;
          setUserInfo(response);
          setUserID(this.userinfo.id);
          setToken(this.userinfo.openId);
          //身份验证完成， 进行队列请求
          this.doquee();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checklogin() {
      this.getAxios("/User/CheckSaoLogin", {
        guid: this.loginguid,
      })
        .then((response) => {
          console.log(response);
          if (response == "no") {
            if (this.ischecking) {
              this.checklogin();
            }
          } else {
            Toast.success("登录成功");
            this.closemodal();
            this.userinfo = response;
            setUserInfo(response);
            setUserID(this.userinfo.id);
            setToken(this.userinfo.openId);
            //身份验证完成， 进行队列请求
            this.doquee();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //给全局调用
    setUserInfo() {
      this.userinfo = getUserInfo();
    },
    gotolink() {
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
.logintext {
  /* font-size: 20px; */
  color: white;
}
</style>