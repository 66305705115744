<template>
  <div class="container con">
    <div style="margin-top: 120px">
      <van-row type="flex" justify="center">
        <van-col span="6">余额</van-col>

        <van-col span="6"
          >{{ fanliInof.yue }} <van-icon name="replay" @click="getinfo" />元
        </van-col>
      </van-row>
    </div>
    <div style="margin-top: 20px">
      <van-button
        v-if="!showForm"
        type="primary"
        class="txbutton"
        size="small"
        @click="onclickTX"
        >我 要 提 现
      </van-button>
    </div>

    <div style="margin-top: 20px" v-if="showForm">
      <van-form @submit="onSubmit">
        <van-field
          v-model="txForm.txje"
          name="txje"
          type="number"
          :formatter="jeformatter"
          label="提现金额"
          placeholder="请填写提现金额"
          :rules="[{ required: true }]"
        >
          <template #button>
            <van-button
              size="mini"
              type="primary"
              native-type="button"
              @click="setall"
              >全部提现</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="txForm.alipayid"
          name="alipayid"
          label="支付宝账号"
          placeholder="请填写支付宝账号"
          :rules="[{ required: true  }]"
        />
        <van-field
          v-model="txForm.alipayName"
          name="alipayName"
          label="支付宝姓名"
          placeholder="请填写支付宝姓名"
          :rules="[{ required: true  }]"
        />
        <!-- <van-field
          v-model="txForm.wexinpayid"
          name="wexinpayid"
          label="微信支付(未开通)"
          disabled
          placeholder="暂未开通"
          :rules="[{ required: false }]"
        /> -->
        <div style="margin: 16px">
          <van-button
            style="width: 60%"
            round
            type="info"
            native-type="submit"
            size="small"
          >
            提交
          </van-button>
          <van-button
            style="margin-left: 20px; width: 28%"
            native-type="button"
            round
            plain
            type="info"
            @click="onclickTX"
            size="small"
          >
            取消
          </van-button>
        </div>
      </van-form>
    </div>

    <div style="margin-top: 20px">
      <van-button
        plain
        type="primary"
        class="txbutton"
        size="small"
        @click="gotolink"
        >查 看 详 情 ></van-button
      >
    </div>

    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast, Icon, List } from "vant";
import { isLogin } from "@/api/localStorage";
import TaskLogin from "@/components/TaskLogin.vue";
export default {
  components: {
    tasklogin: TaskLogin,
  },

  data() {
    return {
      tabactive: 0,
      qCode: "",
      showForm: false,
      fanliInof: {
        yue: "0",
      },
      txForm: {
        txje: "",

        alipayid: "",
        alipayName: "",
        wexinpayid: "暂不支持微信支付到账号",
      },
      txListPage: {
        list: [],
      },
    };
  },

  created: function () {
    this.getinfo();
    //  this.txList();
  },

  methods: {
    getinfo: function () {
      var _this = this;
      this.fanliInof.yue = "0";
      this.getAxiosQ("/ToolsFanLi/FanliInfo", {},"", function (response) {
        _this.fanliInof = response;
      });
    },
    onclickTX: function () {
      this.showForm = !this.showForm;
    },
    setall: function () {
      // if (this.fanliInof.yue > 0) {
      this.txForm.txje = this.fanliInof.yue;
      // }
    },
    jeformatter: function (value) {
      if (value > this.fanliInof.yue) {
        return this.fanliInof.yue;
      } else {
        return value;
      }
    },

    //提交申请
    onSubmit: function (e) {
      console.log(e);
        if(e.txje==0)
      {
        alert("没有余额可提现")
        return;
      }
      if(e.txje=="")
      {
        alert("请填写金额")
        return;
      }
       if(e.alipayid=="")
      {
        alert("请填写支付宝账号")
        return;
      }
       if(e.alipayName=="")
      {
        alert("请填写支付宝姓名")
        return;
      }

      var _this = this;
      this.postAxios("/ToolsFanLi/TxAdd", e, "申请").then((response) => {
        // this.fanliInof = response
        _this.showForm = false;
        _this.getinfo();
        //  _this.txList();
        console.log(response);
      });
    },

    gotolink: function () {
      this.$router.push("/FanLiList");
    },
  },
};
</script>

<style scoped>
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}

.txbutton {
  width: 180px;
}

.blackgroup {
  width: 200px;
  background-color: #39a9ed;
  height: 20px;
}
</style>
