<template>
  <div class="container con">
    <div style="height: 25px;"></div>
    <b-card-group deck>
      <b-card
        :id="index"
        v-for="(item,index) in toolsList"
        :key="index"
        v-b-hover="handleHover"
        :border-variant=" item.isHovered? item.bordervariant:''"
        :header="item.header"
        :header-bg-variant="item.headerbgvariant"
        :header-text-variant="item.istest? 'gray':item.headertextvariant"
        :bg-variant="item.bgvariant"
        align="center"
         @click="gotolink(item.url)"
      >
        <b-card-text>{{item.des}}</b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  data() {
    return {
      toolsList: [
        {
          headerbgvariant: "dark",
          bordervariant: "secondary",
          header: "抖音无水印下载",
          headertextvariant: "white",
          des: "帮您下载抖音的视频，不带右上角的水印。支持手机端。",
          isHovered: false,
          url:"/douyin"
        },
        {
          bordervariant: "primary",
          header: "文库代下载",
          headerbgvariant: "primary",
          headertextvariant: "white",
          des: "为了下个PPT，开了个会员，帮大家下载吧。",
          isHovered: false,
          url:"/wenku"
        },
        {
          bordervariant: "info",
          header: "海豹车机",
          headerbgvariant: "info",
          headertextvariant: "white",
          des: "方便车机上用。",
          isHovered: false,
          url:"/byd"
        },
        {
          bgvariant: "light",
          header: "占位置的女朋友",
          des: "我男朋友爱学习，我来陪他。",
          isHovered: false,
          istest:true,
        }
      ]
    };
  },
  created: function() {},

  methods: {
    gotolink(url){
 this.$router.push(url)
    },
    handleHover(e, b) {
     
      var index=0;
      if (e) {
        
        index=b.toElement.id
      } else {
     
        index=b.fromElement.id
      }

       this.toolsList[index].isHovered =  !this.toolsList[index].isHovered;
    }
  }
};
</script>

<style scoped>
</style>
