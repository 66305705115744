<template>
  <div class="con">
    <div style="height: 50px;"></div>
    <h2>音乐搜索器</h2>
    <div style="height: 40px;"></div>

    <b-form-group @change="radiochange">
      <div class="radio">
        <b-form-radio v-model="selected" name="some-radios" value="0" @change="radiochange">音乐名</b-form-radio>
        <b-form-radio
          v-model="selected"
          name="some-radios"
          value="1"
          @change="radiochange"
        >歌单地址 (✔QQ音乐)</b-form-radio>
        <!--  ✔网易云音乐 -->
      </div>
    </b-form-group>
    <b-form-input v-model="inputtext" :placeholder="placeholdertext"></b-form-input>
    <div style="height: 10px;"></div>
    <b-button block variant="success" @click="getapi">{{buttontext}}</b-button>
  </div>
</template>

<script>
 
export default {
  data() {
    return {
      placeholdertext: "示例：告白气球",
      buttontext: "下载",
      selected: 0,
      inputtext: ""
    };
  },
  created: function() {},

  methods: {
    radiochange(e) {
      console.log(e);
      if (e == 0) {
        this.placeholdertext = "示例：告白气球";
        this.buttontext = "下载";
      } else if (e == 1) {
        this.placeholdertext = "示例：http://";
        this.buttontext = "添加任务";
      }
    },
    getapi() {
      this.getAxios("/ToolsMP3/AddTask", {
            params: {
              url: this.inputtext
            }
        })
        .then(response => {
          // this.meals = response.data.categories;
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.con {
  align-content: center;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
.radio {
  align-items: left;
  text-align: left;
}
</style>
