<template>
  <div class="container con">
    <div style="height: 50px;"></div>
    <h2>超级搜索</h2>
    <div style="height: 40px;"></div>
    <b-form-input v-model="inputtext" :placeholder="placeholdertext"></b-form-input>
    <div style="height: 20px;"></div>
    <b-button block variant="success" @click="search">{{buttontext}}</b-button>
    <div style="height: 10px;"></div>
    <div>
      <tbitem v-for="(item,index) in datalist" :key="index"></tbitem>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isLogin } from "@/api/localStorage";
import TbItem from "@/components/TbItem.vue";

export default {
  metaInfo: {
    title: "曼哥返利",
    meta: [
      {
        name: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利",
        content: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利"
      }
    ],
    link: [
      {
        rel: "supersearch",
        href: "https://tools.mgee.vip/supersearch"
      }
    ]
  },
  components: {
    tbitem: TbItem
  },
  data() {
    return {
      qCode: "",
      datalist: [],

      pagesize: 20,
      currentPage: 1,
      tabaction: 0,
      totalitems: 0,

      placeholdertext: "输入关键字，比如：iPhone手机膜",
      buttontext: "搜索",
      inputtext: ""
    };
  },
  created: function() {},
  watch: {
    //由于组件自带的变更页数有bug 用vue监控数据来控制加载数据
    currentPage(data) {}
  },
  methods: {
    search() {
      var _this = this;
      this.getAxiosQ(
        "/ToolsSuperSearch/SearchTB",
        {
          q: this.inputtext,
          hasCoupon: true,
          page_size: this.pagesize,
          page_no: this.currentPage,
          sort: 0
        },
        "搜索",
        function(response) {
          console.log(response);
          _this.datalist = response;
        }
      );
    },
    radiochange() {},
    add() {
      var guest = "AddTask";
      var islogin = isLogin();
      if (!islogin) {
        guest = "GuestAdd";
      }
      if (this.inputtext.indexOf("wenku.baidu.com") == -1) {
        Toast.fail("请输入正确的文库链接");
        return;
      }
      this.getAxios(
        "/ToolsWenku/" + guest,
        { inputstr: this.inputtext },
        "提交"
      ).then(response => {
        if (islogin) {
          this.totalitems = 0;
          this.pageIndex = 1;
          this.onLoadList();
        } else {
          //没登陆的 显示二维码
          this.qCode = response.ewm;
          this.$refs.tasklogin1.open(response.taskId);
        }
      });
    },
    changepage() {
      this.onLoadList();
    },

    exportpng: function(url) {
      window.location.href = url;
    }
  }
};
</script>

<style scoped>
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}
.radio {
  align-items: left;
  text-align: left;
}
.boxview {
  padding: 10px 10px 0px 10px;
  text-align: left;
  min-height: 280px;
  border-style: solid;
  border-color: lightgray;
  background-color: white;
}
.list {
  min-height: 240px;
}
.cellitem {
  border-bottom: 1px solid lightgray;
}
</style>
