<template>
	<div>
		<div  class="app" id="app">
			<Navbar />
			<router-view style="min-height: 90vh;	overflow: auto;" />
			<Foot />
		</div>

	</div>
</template>
<script>
	//  import HelloWorld from './components/HelloWorld.vue'
	import Navbar from "./components/Navbar.vue";
	import Foot from "./components/Foot.vue";
	export default {
		name: "navbar",
		components: {
			Navbar,
			Foot
		},

		methods: {

		}
	};
</script>
<style scoped>
	.app {
		font-family: Roboto, 'Segoe UI', Arial, 'Microsoft Yahei', sans-serif;
		font-size: 81.25%;
		/* background-color: rgb(53, 54, 58); */
		background-color: #f7f8fa;
	}
</style>
