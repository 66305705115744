<template>
  <div class="container con">
    <div style="height: 20px"></div>
    <div>订单查询</div>
    <div style="height: 20px"></div>

    <b-form-textarea
      id="richtext"
      v-model="inputtext"
      :placeholder="placeholdertext"
      rows="4"
    ></b-form-textarea>

    <div style="height: 15px"></div>
    <b-button class="buttonv" squared variant="success" @click="onclickQuery">{{
      buttontext
    }}</b-button>

    <b-button
      style="margin-left: 20px"
      class="buttonv"
      squared
      variant="outline-secondary"
      @click="onclickClear"
      >清空</b-button
    >

    <div style="height: 15px"></div>

    <div class="sharev">{{ shareText }}</div>
    <!-- 选择栏 -->

    <div>
      <b-card title="查询结果" sub-title="Card subtitle">
        <b-card-text>
          Some quick example text to build on the <em>card title</em> and make
          up the bulk of the card's content.
        </b-card-text>

        <b-card-text>A second paragraph of text in the card.</b-card-text>

        <a href="#" class="card-link">Card link</a>
        <b-link href="#" class="card-link">Another link</b-link>
      </b-card>
    </div>

    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { isLogin } from "@/api/localStorage";
import TaskLogin from "@/components/TaskLogin.vue";

export default {
  metaInfo: {
    meta: [
      {
        // set meta
        name: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利",
        content: "曼哥工具箱,MGee,抖音,文库，返利，淘宝返利",
      },
    ],
    link: [
      {
        // set link
        rel: "report",
        href: "https://tools.mgee.vip/report",
      },
    ],
  },
  components: {
    tasklogin: TaskLogin,
  },

  data() {
    return {
      qCode: "",
      datalist: [],
      toolsList: [],
      isget: false,
      islj: false,
      tkl: "",
      lj: "",
      itemid: "",

      currentPage: 1,
      tabaction: 0,
      totalitems: 0,

      shareText: "",
      placeholdertext: "输入查询内容",
      // buttontext: "一键黏贴&查询",
      buttontext: "查询",
      inputtext: "",
    };
  },

  created: function () {
    this.onclickClear();
    this.onLoadList();
    var _this = this;
    // navigator.clipboard.readText().then((clipText) => {
    //   _this.inputtext = clipText;
    // });
  },
  watch: {
    //由于组件自带的变更页数有bug 用vue监控数据来控制加载数据
    currentPage(data) {
      this.onLoadList();
    },
  },
  methods: {
    onclickClear() {
      this.itemid = "";
      this.islj = false;
      this.isget = false; //已经获取的标记
      this.tkl = "";
      this.inputtext = "";
      this.toolsList = [
        {
          bordervariant: "primary",
          header: "返利口令",
          // headerbgvariant: "gray",
          headertextvariant: "black",
          des: "不用礼金，确认结算后返现。",
          isHovered: false,
          type: "fl",
          tkl: "",
          subdes: "",
        },
        {
          bordervariant: "info",
          header: "礼金口令",
          // headerbgvariant: "gray",
          headertextvariant: "black",
          des: "发放淘礼金，提前享受返利，适用于自购。",
          isHovered: false,
          type: "lj",
          tkl: "",
          subdes: "",
        },
      ];
    },
    onclickQuery() {
      var _this = this;
      try {
        navigator.clipboard.readText().then((clipText) => {
          _this.inputtext = clipText;
          _this.Query();
        });
      } catch (e) {
        // var doc = window.document.getElementById("richtext");
        // doc.focus();
        // document.execCommand("paste");
        _this.Query();
      }
    },
    Query() {
      this.isget = false;
      var islogin = isLogin();
      if (!islogin) {
        Toast.fail("未登录");
        return;
      }
      if (this.inputtext.length < 2) {
        Toast.fail("请输入内容");
        return;
      }
      this.getAxios(
        "/Fanli/CopyFanLiStr",
        { inputstr: this.inputtext },
        "查询"
      ).then((response) => {
        if (!response.itemid) {
          return;
        }

        if (islogin) {
          this.itemid = response.itemid;
          this.islj = response.islj;
          this.isget = true; //已经获取的标记
          this.tkl = response.flKouLin;

          this.toolsList[0].des = response.flKouLin + "\n\r" + response.flstr;
          this.toolsList[0].subdes = "【点击复制】";
          this.toolsList[0].headerbgvariant = "primary";
          this.toolsList[0].headertextvariant = "white";

          if (response.islj) {
            this.toolsList[1].des = response.ljstr;
            this.toolsList[1].subdes = "【点击生成礼金口令】";
            this.toolsList[1].headerbgvariant = "info";
            this.toolsList[1].headertextvariant = "white";
          }

          this.totalitems = 0;
          this.pageIndex = 1;
          this.onLoadList();
        } else {
          //没登陆的 显示二维码
          this.qCode = response.ewm;
          this.$refs.tasklogin1.open(response.taskId);
        }
      });
    },
    changepage() {
      this.onLoadList();
    },

    onLoadList() {
      var _this = this;
      this.getAxiosQ(
        "/Report/List",
        {
          pageIndex: this.currentPage,
          pageSize: 5,
        },
        "",
        function (response) {
          if (_this.totalitems == 0) {
            _this.totalitems = response.totalitem;
          }
          var index = 1;
          response.list.forEach((element) => {
            element.richText =
              "【" +
              (index + (_this.currentPage - 1) * 5) +
              "】" +
              element.richText +
              "";
            index = index + 1;
          });
          if (response.list.length > 0) {
            _this.tabaction = 1;
          }

          _this.datalist = response.list;
        }
      );
    },

    //点击两个模块
    onClickcopy(type) {
      if (!this.isget) {
        return;
      }

      switch (type) {
        case "lj":
          this.ljwork();
          break;
        case "fl":
          this.copyfl();
          break;
      }
    },

    //礼金需要先生成 后复制
    ljwork() {
      if (this.lj) {
        this.copylj();
      } else {
        this.getlj();
      }
    },
    getlj() {
      if (!this.islj) {
        Dialog.alert({
          message: "此宝贝返利太低不足发放淘礼金，只能返利。",
        }).then(() => {
          // on close
        });

        return;
      }
      this.getAxios("/Fanli/CopyTlj", { itemid: this.itemid }, "生成").then(
        (response) => {
          if (!response.iskoul) {
            alert("此宝贝返利太低不足发放淘礼金，只能返利。");
          }
          this.lj = response.koul;
          this.tklreout = "【点击口令可以直接复制】";

          this.toolsList[1].des = response.koul + "\n\r" + response.ljstr;
          this.toolsList[1].subdes = "【再次点击复制】";
        }
      );
    },

    copylj() {
      var createInput = document.createElement("input");
      // 防止键盘被调起
      createInput.setAttribute("readonly", "readonly");
      createInput.setAttribute("value", this.lj);
      document.body.appendChild(createInput);

      createInput.select(); //ios 不支持 但chrome 支持
      createInput.setSelectionRange(0, createInput.value.length); // chrome 不支持 但ios支持
      // alert(createInput.value.length)
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        alert("复制成功");
      } else {
        //   alert("你的手机不支持复制功能");
      }
      document.body.removeChild(createInput);
    },
    ///返利口令点击：进行复制
    copyfl() {
      if (!this.tkl) {
        return;
      }
      var createInput = document.createElement("input");
      // 防止键盘被调起
      createInput.setAttribute("readonly", "readonly");
      createInput.setAttribute("value", this.tkl);
      document.body.appendChild(createInput);

      createInput.select(); //ios 不支持 但chrome 支持
      createInput.setSelectionRange(0, createInput.value.length); // chrome 不支持 但ios支持
      // alert(createInput.value.length)
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        alert("复制成功");
      } else {
        //   alert("你的手机不支持复制功能");
      }
      document.body.removeChild(createInput);
    },

    handleHover(e, b) {
      var index = 0;
      if (e) {
        index = b.toElement.id;
      } else {
        index = b.fromElement.id;
      }

      this.toolsList[index].isHovered = !this.toolsList[index].isHovered;
    },
  },
};
</script>

<style scoped>
.buttonv {
  width: 160px;
}
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}
.radio {
  align-items: left;
  text-align: left;
}
.boxview {
  padding: 10px 10px 0px 10px;
  text-align: left;
  min-height: 280px;
  border-style: solid;
  border-color: lightgray;
  background-color: white;
}
.list {
  min-height: 240px;
}
.cellitem {
  border-bottom: 1px solid lightgray;
}
.sharev {
  white-space: pre-wrap;
  text-align: left;
}
.subsharev {
  color: red;
  white-space: pre-wrap;
  text-align: center;
}
</style>
