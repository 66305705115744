<template>
  <div>
    <van-row>
      <van-col span="8">
        <van-image class="headimage" fit="contain" :src="item.pict_url" />
      </van-col>
      <van-col span="16">
        <div>
          <div>
            <van-tag v-if="item.user_type==1" type="danger">天猫</van-tag>
            <van-tag v-if="item.user_type==0" type="warning">淘宝</van-tag>
            {{item.short_title}}
          </div>
          <div>券：{{item.coupon_amount}} 已售{{item.volume>10000? (item.volume/10000)+'万':item.volume}}件</div>
          <div>
            券后￥
            <label>{{item.zk_final_price-item.coupon_amount}}</label>
          </div>
        </div>

        <div class="tljbuttonview">
          <!-- <van-button type="warning">领取礼金 {{(item.commission_rate/100)*(item.zk_final_price-item.coupon_amount) }}</van-button> -->
          <van-button type="warning">领取礼金 {{item.tlj }}</van-button>
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  name: "TbItem",
  props: {
    item: Object
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.headimage {
  width: 100%;
  height: 100%;
}
.tljbuttonview{
  	/* position: absolute;
		right: 25rpx;
		bottom: 25rpx; */
}
</style>