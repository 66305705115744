import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue ,BootstrapVueIcons }from 'bootstrap-vue'
import router from './router'
import VueQriously from 'vue-qriously'
import Vant from 'vant';
import axiosApi from '@/api/axios.js'
import 'vant/lib/index.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import MetaInfo from 'vue-meta-info';
import VueClipboard from 'vue-clipboard2'
 


Vue.use(VueClipboard)
//==============================================
// 直接通过code兑换授权就好了 好像也不用引入sdk
// import { WechatPlugin } from 'vux'
// Vue.use(WechatPlugin)
// console.log(Vue.wechat) // 可以直接访问 wx 对象。
//================================================

Vue.config.productionTip = false

Vue.use(axiosApi);
Vue.use(Vant);
Vue.use(VueQriously)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
 

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
