<template>
  <div class="container">
    <div class="con">
      <div style="height: 40px"></div>
      <h2>视频解析</h2>
      <div style="height: 30px"></div>
      <!-- <b-form-input v-model="inputtext" :placeholder="placeholdertext"></b-form-input> -->

      <b-form-textarea
        v-model="inputtext"
        :placeholder="placeholdertext"
        rows="3"
      ></b-form-textarea>

      <div style="height: 10px"></div>
      <b-button block variant="success" @click="add">{{ buttontext }}</b-button>
      <!-- add -->
      <div style="height: 10px;"></div>
    </div>
    <!-- 下载按钮 -->
    <div v-if="isgetdata">
    <div style="width: 100%; text-align: center;">
       <video  style="height:300px" :src="reOutObj.videourl"  controls show-center-play-btn show-play-btn  
		picture-in-picture-mode="['push', 'pop']"></video>
    </div>

     
     <div class="copyitemv">
        <input id="videourl" class="inputv" :value="reOutObj.videourl" />
        <b-button variant="info" size="sm" @click="onClickCopyVideurl">
          一键复制视频链接
        </b-button>
      </div>
      <div class="copyitemv">
        <input id="imageurl" class="inputv" :value="reOutObj.imageurl" />
        <b-button variant="info" size="sm" @click="onClickCopyImageurl">
          一键复制封面图片
        </b-button>
      </div>
      <div class="copyitemv">
        <input id="des" class="inputv" :value="reOutObj.des" />
        <b-button variant="info" size="sm" @click="onClickCopyDes">
          一键复制描述文字
        </b-button>
      </div>
    </div>
    <tasklogin :ewmurl="qCode" ref="tasklogin1"></tasklogin>
  </div>
</template>

<script>
import { Toast } from "vant";
import { isLogin } from "@/api/localStorage";
import TaskLogin from "@/components/TaskLogin.vue";
export default {
  components: {
    tasklogin: TaskLogin,
  },

  data() {
    return {
      qCode: "",
      reOutObj: {},
      isgetdata: false,
      currentPage: 1,
      tabaction: 0,
      totalitems: 0,

      placeholdertext: "输入视频连接或者分享口令。",
      buttontext: "解   析",
      inputtext: "",
    };
  },

  created: function () {
    // this.onLoadList();
  },
  watch: {
    currentPage(data) {
      console.log(data);
      this.onLoadList();
    },
  },
  methods: {
    add() {
      var guest = "AddTask";
      var islogin = isLogin();
      if (!islogin) {
        guest = "GuestAddTask";
      }
      if (this.inputtext.indexOf("https://v.douyin.com") == -1) {
        Toast.fail("请输入正确的分享链接");
        return;
      }
      this.postAxios(
        "/ToolsDouyin/" + guest,
        { inputstr: this.inputtext },
        "解析"
      ).then((response) => {
        if (islogin) {
          console.log(response);
          this.totalitems = 0;
          this.pageIndex = 1;
          this.reOutObj = response;
          this.isgetdata = true;
          // this.onLoadList();
        } else {
          //没登陆的 显示二维码
          this.qCode = response.ewm;
          this.$refs.tasklogin1.open(response.taskId);
        }
      });
    },

    checkVip:function(){

    },

    changepage() {
      this.onLoadList();
    },

    onLoadList() {
      var _this = this;
      this.getAxiosQ(
        "/ToolsDouyin/List",
        {
          pageIndex: this.currentPage,
          pageSize: 5,
        },
        "",
        function (response) {
          if (_this.totalitems == 0) {
            _this.totalitems = response.totalitem;
          }
          var index = 1;
          response.list.forEach((element) => {
            element.des =
              "【" +
              (index + (_this.currentPage - 1) * 5) +
              "】" +
              element.des +
              "";
            index = index + 1;
          });
          if (response.list.length > 0) {
            _this.tabaction = 1;
          }

          _this.datalist = response.list;
        }
      );
    },
    exportpng: function (url) {
      window.location.href = url;
    },
    onClickCopyVideurl: function () {
      var videourl = document.getElementById("videourl");
      videourl.select(); // 选择对象
      document.execCommand("Copy");
    },
    onClickCopyImageurl: function () {
      var imageurl = document.getElementById("imageurl");
      imageurl.select(); // 选择对象
      document.execCommand("Copy");
    },
    onClickCopyDes: function () {
      var des = document.getElementById("des");
      des.select(); // 选择对象
      document.execCommand("Copy");
    },
  },
};
</script>

<style scoped>
.copyitemv {
  line-height: 20px;
}
.con {
  align-content: center;
  align-items: center;
  text-align: center;
}
.radio {
  align-items: left;
  text-align: left;
}
.boxview {
  padding: 10px 10px 0px 10px;
  text-align: left;
  min-height: 280px;
  border-style: solid;
  border-color: lightgray;
  background-color: white;
}
.list {
  min-height: 240px;
}
.cellitem {
  border-bottom: 1px solid lightgray;
}
.video {
  width: 500px;
  height: 500px;
}
.inputv {
  margin-top: 15px;
  margin-right: 20px;
  width: 80%;
  border-bottom: 1px dashed #000;
}
</style>
